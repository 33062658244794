/**
 * Toggle Theme
 */
export default (() => {
  // check for the items with class js-moon and js-sun
  const moon = document.querySelector('.js-moon')
  const sun = document.querySelector('.js-sun')
  // if these exist, add event listener to them
  if (moon && sun) {
    moon.addEventListener('click', () => {
      toggleTheme()
    })
    sun.addEventListener('click', () => {
      toggleTheme()
    })
  }
  const checkbox = document.getElementById('theme-mode')

  const toggleTheme = () => {
    checkbox.checked = !checkbox.checked
    checkbox.dispatchEvent(new Event('change')) // Trigger change event if needed
  }
})()
